import React from "react"
import {StaticQuery, graphql } from "gatsby"
import Footer from './footer.js'
// import './menu.scss' 


const Footer_bottom = ({children}) => (
  <StaticQuery
  query={graphql`
    {
      wordpress {
        optionsPage {
          site_details{
            message
          }
        }
      }
    }
  `}
    render={data => <Footer data={data.wordpress.optionsPage.site_details.message}>{children}</Footer>}
  ></StaticQuery>
)

export default Footer_bottom
