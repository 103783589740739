import React from "react";
import './social_menu.scss';



function SocialMenu(props) {
    return (
        <div className="social_menu">
            {console.log("social menu")}
            {console.log(props)}
        {props.data.map((item)=>(
            <a target="_blank" href={item.linkTo} dangerouslySetInnerHTML={{ __html: item.svgIcon }} >
             
            </a>

        ))}
        </div>
    )
}


export default SocialMenu