import React from "react";
import { Link } from "gatsby";
import './nav.scss';


export default function Nav(props) {

  return (
    <div className={"nav_main " + (props.mobileStatus ? "active" : "not-active") }>
     
      <div>

      {props.data.allWordpressMenuItem.edges.map((node) => (
        <div className="nav_item">

          <Link to={node.node.url}> {node.node.label}</Link>
         
        </div>
      ))}
    </div>
    </div>
  )
}



