import React from "react"
import {StaticQuery, graphql } from "gatsby"
import Menu from './menu.js'
import './menu.scss' 


const ComponentName = ({props}) => (
  <StaticQuery
    query={graphql`
    {
      allWordpressMenuItem(filter: {locations: {eq: GATSBY_FOOTER_MENU}}) {
        nodes {
          id
          label
          title
          path
          parentId
          url
        }
    }
  }
    
    `}
    render={data => <Menu data={flatListToHierarchical(data.allWordpressMenuItem.nodes)} {...props}/>}
  ></StaticQuery>
)

export default ComponentName



const flatListToHierarchical = (
  data = [],
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = []
  const childrenOf = {}
  data.forEach(item => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })
  return tree
}
 
