
import React from "react";
import Footer from '../components/footer/index';
import Header from '../components/header/header';
import Menus from '../components/menu2/index';
// import SEO from '../components/seo/seo'
// import Banner from '../components/banner/index'
import '../styles/reset.scss';
import './layout.scss';

// the layout section 
const Layout = ({ children }) => (
  <div style={{ 
     }}>
       {/* <Banner/> */}
       <Header>
       </Header>
            {children}
        <Footer>
          <Menus/>
        </Footer>
  </div>
);

export default Layout