import React from "react"
import './footer.scss'
import {Link} from 'gatsby'


export default function Footer(props) {
  console.log(props)
  return (
    <div className="footer">
      
    {props.children}
      <div className="bottom_footer">{props.data} <Link to="/terms"> Terms and Conditions</Link></div>
    </div>
  )
}