import React from "react"
import {Link} from "gatsby"
import './menu.scss';
import BottomLogo from '../../images/logo-alt2.png';
import SocialMedia from '../social_menu/index';

// Menu Component
export default function Menu(props) {

  return (
    <div className="menu">
      
      
         {props.data.map((list) => (
          <div className="menu_item">

          <h2>{list.label}</h2>
            <ul >
            {list.children.map((item) =>(
              <li>
                {/* {(item.type === 'custom') ? <a href={item.url}>{item.label}</a> : <Link to={"/" + item.path}> {item.label}</Link>} */}
                <Link to={item.url}> {item.label}</Link>
              </li>
            ))}
            </ul>
            </div>
          ))}   
      <LogoWhite>
         <SocialMedia />
      </LogoWhite>
    </div>
    )
}




function LogoWhite(props){

  return(
    <div className="bottom_container">
          <img className="bottom_logo" src={BottomLogo} alt=""></img>
        {props.children}
      </div>
  )
}