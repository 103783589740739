import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SocialMenu from "./social_menu.js";

// import Img from "gatsby-image"

const SocialMedia = () => (
    <StaticQuery
    query={graphql`
      {
        wordpress {
          optionsPage {
            social_media_links {
              type {
                svgIcon
                linkTo
              }
            }
          }
        }
      }
    `}
    render={data => <SocialMenu data={data.wordpress.optionsPage.social_media_links.type} />}
  ></StaticQuery>
)

export default SocialMedia