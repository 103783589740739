import React from "react"
import {StaticQuery, graphql } from "gatsby"
import Nav from './nav.js'
import './nav.scss'

export default function ComponentName(props) {
  return (
  <StaticQuery
    query={graphql`
     {
      allWordpressMenuItem(filter: {locations: {eq: GATSBY_HEADER_MENU}}) {
        edges {
          node {
            id
            url
            title
            label
          }
        }
      }
    }
    
  `}
    render={data => <Nav data={data} {...props} />}
  ></StaticQuery>
  )
  }

